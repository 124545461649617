var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","transition":"fab-transition","max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-space-between mb-5",attrs:{"id":"modal-header"}},[_c('v-card-title',{staticClass:"py-3"},[_vm._v("Upload Video")]),_c('div',{staticClass:"mt-3 mr-2"},[_c('v-btn',{attrs:{"text":""}},[_vm._v(" Upload With Classic ")]),_c('v-btn',{attrs:{"icon":"","text":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),(!_vm.uploaded)?_c('v-card-text',{staticClass:"d-flex flex-column align-center my-md-12 py-md-12 my-sm-8 py-sm-8 my-xs-0 py-xs-0 my-12 py-12"},[(!_vm.uploading)?_c('div',{staticClass:"text-center"},[_c('div',[_c('v-btn',{staticClass:"grey lighten-2 mb-4",staticStyle:{"height":"104px","width":"104px"},attrs:{"icon":""},on:{"click":_vm.selectFile}},[_c('v-icon',{staticClass:"grey--text text--darken-1",attrs:{"x-large":""}},[_vm._v("mdi-upload")])],1)],1),_c('ValidationProvider',{ref:"provider",attrs:{"rules":"required|size:5000","name":"file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{ref:"fileInput",attrs:{"accept":"video/mp4","placeholder":"Pick an video","prepend-icon":"mdi-video","error-messages":errors},on:{"change":_vm.uploadVideo},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}})]}}],null,false,604243626)}),_c('v-btn',{staticClass:"blue darken-3 flat white--text mt-4",attrs:{"type":"submit","depressed":""},on:{"click":function($event){return _vm.$refs.fileInput.$refs.input.click()}}},[_vm._v("Select File")])],1):_c('v-progress-circular',{attrs:{"rotate":360,"size":100,"width":15,"value":_vm.value,"color":"teal"}},[_vm._v(" "+_vm._s(_vm.value)+" ")])],1):_c('v-card-text',[_c('h2',{staticClass:"mb-6"},[_vm._v("Details")]),_c('v-row',[_c('v-col',{attrs:{"order":"last","order-sm":"last","order-md":"first","order-lg":"first","order-xl":"first","cols":"12","sm":"12","md":"8","lg":"8"}},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-3",attrs:{"error-messages":errors,"label":"Title (required)","filled":"","dense":"","counter":"100","max-length":"100"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Description","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","auto-grow":"","error-messages":errors,"label":"Description","placeholder":"Tell viewers about your video","rows":"5","counter":"5000","max-length":"5000","row-height":"20"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Visibilty","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.visibilty,"error-messages":errors,"filled":"","label":"Visibilty"},model:{value:(_vm.formData.visibilty),callback:function ($$v) {_vm.$set(_vm.formData, "visibilty", $$v)},expression:"formData.visibilty"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Cateogry","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categoriesTitles,"error-messages":errors,"filled":"","label":"Categories","loading":_vm.categoryLoading},model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}})]}}])}),_c('div',{staticClass:"mt-6 d-flex justify-space-between"},[_c('v-btn',{staticClass:"primary",attrs:{"loading":_vm.submitLoading,"type":"submit","depressed":""}},[_vm._v("Submit")])],1)],1)])],1),_c('v-col',{staticClass:"text-center",attrs:{"order-sm":"1","cols":"12","sm":"12","md":"4","lg":"4"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.toggleShow}},[_vm._v("Upload Thumbnails")]),_c('my-upload',{attrs:{"field":"thumbnail","method":"PUT","width":1280,"height":720,"url":_vm.url,"headers":_vm.headers,"img-format":"jpg","langType":"en"},on:{"crop-success":_vm.cropSuccess},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('v-responsive',{staticClass:"mx-auto",attrs:{"width":"330"}},[(!_vm.imgDataUrl)?_c('div',{staticClass:"px-12",attrs:{"id":"image-placeholder"}},[_c('v-icon',[_vm._v("mdi-image-plus")])],1):_c('v-img',{attrs:{"crossorigin":"anonymous","max-width":"330","height":"350","src":_vm.imgDataUrl}})],1),(_vm.imgDataUrl == '')?_c('p',{staticClass:"red--text"},[_vm._v(" Please upload thumbnail ")]):_vm._e()],1)],1)],1),(!_vm.uploaded)?_c('v-card-actions',[_c('p',{staticClass:"text-center grey--text caption px-12 px-xs-0"},[_vm._v(" By submitting your videos to YouTube, you acknowledge that you agree to YouTube's Terms of Service and Community Guidelines. Please be sure not to violate others' copyright or privacy rights. Learn more ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }